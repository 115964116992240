import React, { useState } from 'react'

import { IconButton, Menu } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import DeleteLesson from './DeleteLesson'
import EditName from './EditName'
import { Lesson } from '../../../../types'
import ParseAgain from './ParseAgain'
import PrintButton from './PrintButton'
import Share from './Share'

const ActionsMenu = ({ lesson }) => {
  const { id, name } = lesson
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {lesson.sentences ? (
          <PrintButton name={name} type={lesson.type} />
        ) : null}
        <EditName lessonId={id} currentName={name} onClose={handleClose} />
        <ParseAgain lesson={lesson} onClose={handleClose} />
        {lesson.sentences ? <Share id={id} onClose={handleClose} /> : null}
        <DeleteLesson lessonId={id} onClose={handleClose} />
      </Menu>
    </div>
  )
}

ActionsMenu.propTypes = {
  lesson: Lesson.isRequired,
}

export default ActionsMenu
