import React from 'react'
import PropTypes from 'prop-types'
import { ListItemIcon, MenuItem, Typography } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print'

import { sendEvent } from '../../../../utils/analytics'

const PrintButton = ({ type }) => {
  const onClick = () => {
    sendEvent('lesson.print', { type })
    window.print()
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <PrintIcon color="primary" />
      </ListItemIcon>
      <Typography color="primary" variant="overline">
        Print
      </Typography>
    </MenuItem>
  )
}

PrintButton.propTypes = {
  type: PropTypes.string.isRequired,
}

export default PrintButton
